import moment from "moment";
/**
 * 扩展合并对象
 */
import url2Img from "@/common/url2Img";
function extend() {
  // 默认不进行深拷贝
  let deep = false;
  let name, options, src, copy, clone, copyIsArray;
  let length = arguments.length;
  // 记录要复制的对象的下标
  let i = 1;
  // 第一个参数不传布尔值的情况下，target 默认是第一个参数
  let target = arguments[0] || {};
  // 如果第一个参数是布尔值，第二个参数是 target
  if (typeof target == "boolean") {
    deep = target;
    target = arguments[i] || {};
    i++;
  }
  // 如果target不是对象，我们是无法进行复制的，所以设为 {}
  if (typeof target !== "object" && !isFunction(target)) {
    target = {};
  }

  // 循环遍历要复制的对象们
  for (; i < length; i++) {
    // 获取当前对象
    options = arguments[i];
    // 要求不能为空 避免 extend(a,,b) 这种情况
    if (options != null) {
      for (name in options) {
        // 目标属性值
        src = target[name];
        // 要复制的对象的属性值
        copy = options[name];

        // 解决循环引用
        if (target === copy) {
          continue;
        }

        // 要递归的对象必须是 plainObject 或者数组
        if (
          deep &&
          copy &&
          (isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))
        ) {
          // 要复制的对象属性值类型需要与目标属性值相同
          if (copyIsArray) {
            copyIsArray = false;
            clone = src && Array.isArray(src) ? src : [];
          } else {
            clone = src && isPlainObject(src) ? src : {};
          }

          target[name] = extend(deep, clone, copy);
        } else if (copy !== undefined) {
          target[name] = copy;
        }
      }
    }
  }

  return target;
}

function isPlainObject(obj) {
  let proto, Ctor;

  // (1) null 肯定不是 Plain Object
  // (2) 使用 Object.property.toString 排除部分宿主对象，比如 window、navigator、global
  if (!obj || {}.toString.call(obj) !== "[object Object]") {
    return false;
  }

  proto = Object.getPrototypeOf(obj);

  // 只有从用 {} 字面量和 new Object 构造的对象，它的原型链才是 null
  if (!proto) {
    return true;
  }

  // (1) 如果 constructor 是对象的一个自有属性，则 Ctor 为 true，函数最后返回 false
  // (2) Function.prototype.toString 无法自定义，以此来判断是同一个内置函数
  Ctor = {}.hasOwnProperty.call(proto, "constructor") && proto.constructor;
  return (
    typeof Ctor === "function" &&
    Function.prototype.toString.call(Ctor) ===
      Function.prototype.toString.call(Object)
  );
}

/**
 * 金额大小写转化
 * @param {*} n
 * @param {*} flag
 */
function simpleChinese(n, flag = false) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return "数据非法";
  var str = "",
    unit = "",
    model = "",
    regExp = () => {};
  if (flag) {
    unit = "千百十亿千百十万千百十元角分";
    model = "零壹贰叁肆伍陆柒捌玖";
    regExp = str => {
      return str
        .replace(/零(千|百|拾|角)/g, "零")
        .replace(/(零)+/g, "零")
        .replace(/零(万|亿|元)/g, "$1")
        .replace(/(亿)万|壹(拾)/g, "$1$2")
        .replace(/^元零?|零分/g, "")
        .replace(/元$/g, "元整");
    };
  } else {
    unit = "千百十亿千百十万千百十元角分";
    model = "零一二三四五六七八九";
    regExp = str => {
      return str
        .replace(/零(千|百|十|角)/g, "零")
        .replace(/(零)+/g, "零")
        .replace(/零(万|亿|元)/g, "$1")
        .replace(/(亿)万|一(十)/g, "$1$2")
        .replace(/^元零?|零分/g, "")
        .replace(/元$/g, "");
    };
  }

  n += "00";
  var p = n.indexOf(".");
  if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
  unit = unit.substr(unit.length - n.length);
  for (var i = 0; i < n.length; i++)
    str += model.charAt(n.charAt(i)) + unit.charAt(i);
  return regExp(str);
}

/**
 * 判断文件类型，是否在给定类型中
 * @param {*} fileType  文件类型
 * @param {*} type      给定类型
 */
function fileTypeCheck(
  fileType,
  type = ["image/jpeg", "image/png", "image/gif"]
) {
  let isCheck = false;
  type.forEach(el => {
    if (fileType === el) {
      isCheck = true;
    }
  });
  return isCheck;
}

/**
 * 获取缩略图地址
 * @param {*} url     图片地址
 * @param {*} width
 * @param {*} height
 */
function getThumb(url, width = 100, height = 100) {
  if (!url) {
    return "";
  }
  let last = url.lastIndexOf(".");
  return `${url.slice(0, last)}_${width}x${height}${url.slice(last)}`;
}

/**
 * 设置网站title
 * @param {string} title
 */
function setTitle(title) {
  title && typeof title === "string" && (document.title = title);
}

/**
 * 定位到指定位置
 * @param {number} x
 * @param {number} y
 */
function scrollTo(x = 0, y = 0) {
  window.scrollTo(x, y);
}

/**
 * textarea换行符替换
 * @param {*} str
 */
function formatHtml(str) {
  return str ? str.replace(/\n|\r\n/g, "<br/>") : "";
}

/**
 * textarea换行符替换
 * @param {*} str
 */
function filterHtml(str) {
  let res = str.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
  res = res.replace(/[|]*\n/, ""); //去除行尾空格
  res = res.replace(/&npsp;/gi, ""); //去掉npsp
  return res;
}

const tree = {
  /**
   * 转换树形结构，添加parent节点
   * @param {tree} tree
   * @param {node} parent
   */
  convertTree(tree, parent) {
    if (isNaN(tree.length)) {
      tree = [tree];
    }
    for (let key in tree) {
      let node = tree[key];
      node.parent = parent;
      if (node.childrenList && node.childrenList.length > 0) {
        this.convertTree(node.childrenList, node);
      }
    }
  },

  /**
   * 根据tree的key值，获取tree的节点
   * @param {tree} tree
   * @param {string} key
   * @param {string} value
   */
  getTreeNodes(tree, key, value) {
    let select = [];
    if (isNaN(tree.length)) {
      tree = [tree];
    }
    for (let i in tree) {
      let node = tree[i];
      if (node[key] == value) {
        select.push(node);
      }
      if (node.childrenList && node.childrenList.length > 0) {
        select = select.concat(
          this.getTreeNodes(node.childrenList, key, value)
        );
      }
    }
    return select;
  },
  /**
   * 获取节点的路径
   * @param {tree} tree
   * @param {string|object} key (key | node)
   * @param {string} value
   */
  getNodePath(tree, key, value) {
    let node =
      typeof key === "string" ? this.getTreeNodes(tree, key, value)[0] : key;
    let path = [];
    while (true) {
      if (!node) {
        return path;
      }
      path.unshift(node);
      node = node.parent;
    }
  }
};

const area = {
  // 去除空的childrenList属性，用于级联选择器
  trim(areaList) {
    areaList.forEach(el => {
      if (el.childrenList) {
        if (el.childrenList.length > 0) {
          area.trim(el.childrenList);
        } else {
          delete el.childrenList;
        }
      }
    });
  },
  // 将 area list转化为 area map
  toMap(areaList) {
    return list.toMap(areaList, {
      key: "id",
      value: "name",
      children: "childrenList"
    });
  }
};

const list = {
  /**
   * 将 list 转化为 map
   * @param {*} origin 原始 list
   * @param {*} opt
   *        key -- map的key对应的list元素属性
   *        value -- map的value对应的list元素属性
   *        children -- 若存在，则将子集也一并转化，值为children的属性值
   * @param {*} map
   */
  toMap(
    origin,
    opt = { key: "id", value: "title", children: "childrenList" },
    map = {}
  ) {
    origin.forEach(el => {
      map[el[opt.key]] = opt.value ? el[opt.value] : el;
      if (opt.children && el[opt.children] && el[opt.children].length > 0) {
        list.toMap(el[opt.children], opt, map);
      }
    });
    return map;
  }
};

/**
 * 校验手机号
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const mobileRules = (rule, value, callback) => {
  // console.log(rule, value, callback)
  if (value !== "") {
    if (/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value) === false) {
      callback(new Error("请输入正确的手机号"));
      return;
    }
    callback();
  } else {
    callback("手机号不能为空");
  }
};

function getDateDuration(t1, t2, abs = true) {
  let duration = moment.duration(moment(t1) - moment(t2)).asDays();
  return abs ? Math.abs(duration) : duration;
}

function numFixed(num, fixed) {
  if (isNaN(num)) {
    return num;
  }
  return Number(num.toFixed(fixed));
}

/**
 * 计算两时间差
 * @param {*} date1
 * @param {*} date2
 */
const timeDiff = (dateBegin, dateEnd) => {
  //如果时间格式是正确的Date，那下面这一步转化时间格式就可以不用了
  //时间差的毫秒数
  let dateDiff = dateEnd.getTime() - dateBegin.getTime();
  //计算出相差天数
  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));
  //计算天数后剩余的毫秒数
  let leave1 = dateDiff % (24 * 3600 * 1000);
  let hoursDiff = Math.floor(leave1 / (3600 * 1000));
  //计算相差分钟数
  //计算小时数后剩余的毫秒数
  let leave2 = leave1 % (3600 * 1000);
  //计算相差分钟数
  let minutesDiff = Math.floor(leave2 / (60 * 1000));
  //计算相差秒数
  //计算分钟数后剩余的毫秒数
  let leave3 = leave2 % (60 * 1000);
  let secondsDiff = Math.round(leave3 / 1000);
  console.log(
    " 相差 " +
      dayDiff +
      "天 " +
      hoursDiff +
      "小时 " +
      minutesDiff +
      " 分钟" +
      secondsDiff +
      " 秒"
  );
  console.log(
    "时间差的毫秒数: " + dateDiff,
    "计算出相差天数: " + dayDiff,
    "计算天数后剩余的毫秒数: " + leave1,
    "计算出小时数: " + hoursDiff,
    "计算相差分钟数: " + minutesDiff,
    "计算相差秒数: " + secondsDiff
  );
  return {
    dateDiff: dateDiff,
    dayDiff: dayDiff,
    hoursDiff: hoursDiff,
    minutesDiff: minutesDiff,
    secondsDiff: secondsDiff
  };
};

export const Utils = {
  simpleChinese,
  isPlainObject,
  extend,
  tree,
  area,
  list,
  fileTypeCheck,
  getThumb,
  setTitle,
  scrollTo,
  formatHtml,
  filterHtml,
  mobileRules,
  getDateDuration,
  timeDiff,
  numFixed,
  url2Img
};
// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: function(Vue, Option) {
    Object.defineProperty(Vue.prototype, "$utils", {
      value: Utils
    });
  },
  Utils
};
