<template>
  <div :class="`${getDivHelper()} ${vx_isScrollLocked ? 'home-lock-scroll' : ''}`" v-loading.fullscreen="!fs && !hideLoading">
    <div v-preloading.opacity="delay && !fs" :style="$route.name == 'login' ? 'height:100%' : 'height:100%'">
      <!--页面头部-->
      <Header v-if="$route.name != 'videoPlay'" :routeName="$route.name" :msgNum="msgNum" :userMsg="userMsg"></Header>
      <router-view v-title="$route.meta.title"></router-view>
      <!-- <Footer v-preloading.opacity="!fs" v-if="$route.name != 'login' && $route.name != 'videoPlay' && false"></Footer> -->
      <NewFooter v-preloading.opacity="!fs" v-if="$route.name != 'login' && $route.name != 'videoPlay'"></NewFooter>
      <Gift :isShow="isShow" @close="close" :info="giftInfo" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import NewFooter from '@/components/newFooter.vue'
import Gift from '@/components/gift.vue'
// import VueCookies from 'vue-cookies'
export default {
  components: {
    Header,
    NewFooter,
    Gift,
  },
  computed: {
    hideLoading() {
      return this.$route.meta.hideLoading
    },
  },
  watch: {
    vx_isReady(v) {
      if (this.usePageReady) {
        this.fs = v
      }
    },
  },
  data: () => {
    return {
      apiData: '',
      fs: false,
      delay: true,
      usePageReady: false,
      msgNum: 0,
      userMsg: {},
      isShow: false,
      giftInfo: {},
    }
  },
  methods: {
    close() {
      this.isShow = false
    },
    getDivHelper() {
      return this.$route.meta.divHelper
    },
    getCurrentUser() {
      return new Promise((resolve) => {
        this.$store.dispatch('getUser').then((res) => {
          this.userMsg = res.result
          if (this.$cookies.get('edu_login') == 1) {
            this.$cookies.remove('edu_login', '/', '.163yun.com')
            console.log('456', this.$cookies.get('edu_login'))
            // this.$cookies.set('edu_login', '', -1, '*.163yun.com')
            if (res.result.enableIndenpentPortal && res.result.exclusiveUrl) {
              location.href = res.result.exclusiveUrl
            }
            if (res.result.enableIndenpentPortal && !res.result.exclusiveUrl) {
              if (location.host === process.env.VUE_APP_CSZYHOST) {
                return
              }
              location.href = '/personal/index?t=4&course=1'
            }
          }
          localStorage.setItem('userInfo', JSON.stringify(res.result))
          resolve(true)
          try {
            if (location.href.split('/')[4].split('?')[0] === 'videoPlay' && this.userMsg) {
              this.userMsg.isFirstLogin = false
            }
          } catch {}
          if (this.userMsg && this.userMsg.isFirstLogin) {
            this.giftInfo = {
              name: this.userMsg.name.split('')[0],
              title: '您已获得课程专属礼包，快去学习吧～',
              btnText: '查看课程礼包',
            }
            this.isShow = true
          }
        })
      })
    },
    // 获取站内信
    fGetMsgNum() {
      this.$http
        .get('/user/unread/message/count')
        .then((res) => {
          this.msgNum = res.result.count
        })
        .catch((msg) => {})
    },
    login() {
      this.$http
        .get('/login')
        .then((res) => {
          sessionStorage.setItem('setLogin', false)
        })
        .catch((msg) => {})
    },
  },
  created() {
    if (sessionStorage.getItem('setLogin') == true || sessionStorage.getItem('setLogin') == null) {
      this.login()
    }
    this.getCurrentUser().then((res) => {
      this.fGetMsgNum()
    })
    this.fs = false
    this.$router.afterEach((to, from) => {
      if (to.meta.scrollLocked) {
        this.vx_lockScroll()
      } else {
        this.vx_releaseScroll()
      }
      this.usePageReady = to.meta.usePageReady
      this.delay = to.meta.delay > 0 || this.usePageReady
      if (!this.usePageReady) {
        if (to.meta.delay) {
          setTimeout(() => {
            this.fs = true
          }, to.meta.delay || 0)
        } else {
          this.fs = true
        }
      }
    })
  },
}
</script>
<style lang="scss">
.home-lock-scroll {
  overflow: hidden !important;
}
</style>
