export function urlConversion(path) {
    let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
    path = path.replace(reg, "http://$2$3$4$5$6");
    return path;
}

export function dealLocationSearch() {
    const urlSearch = location.search;
    const substrUrlSearch = urlSearch.substr(1);  //从起始索引号提取字符串中指定数目的字符
    let arr = substrUrlSearch.split("&"), obj = {}, newarr = [];
    arr.forEach((value) => {
        newarr = value.split("=");
        if(typeof obj[newarr[0]] === "undefined"){  
        obj[newarr[0]] = newarr[1];  
        }        	
    });
    return obj;
}