<template>
  <div class="m-new-footer z-web blue">
    <div class="u-footer-wrap">
      <div class="u-logo">
        <div class="">
          <CMSImage
            :img="logoUrl1"
            :img2x="logoUrl2"
            class="u-img"
          />
        </div>
      </div>
      <div class="u-footer-list">
        <div class="light">
          <div class="m-service">
            <p class="detail-tlt">关于我们</p>
            <div class="detail">
              <a target="_blank" href="https://www.163yun.com/" class="link u-link">网易数帆官网</a>
            </div>
          </div>
          <div class="m-service">
            <p class="detail-tlt">合作联系</p>
            <div class="detail">
              <a target="_self" href="/" class="link u-link">商务合作邮箱：ndip@service.netease.com</a>
            </div>
          </div>
        </div>
      </div>
      <div class="links-code g-l">
        <CMSImage
          img="https://www.163yun.com/res/CommonFooter/weixin.png"
          img2x="https://www.163yun.com/res/CommonFooter/weixin.png"
        />
        <p>关注公众号</p>
      </div>
      <ul class="u-footer-info">
        <div class="m-info-list">
            <a target="_self" class="link link">网易公司版权所有 © 1997-2021</a>
        </div>
        <div class="m-info-list">
            <a target="_self" class="link link">增值电信业务经营许可证 B1-20180288</a>
        </div>
        <div class="m-info-list">
            <a target="_self" href="https://beian.miit.gov.cn/publish/query/indexFirst.action" class="link link">浙ICP备17006647号-18</a>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import logoUrl1 from '@/assets/footerlogo.png'
import logoUrl2 from '@/assets/footerlogo_2x.png'
import CMSImage from './Image.vue'
export default {
  name: "Footer",
  components: {
    CMSImage
  },
  data() {
    return {
      imgUrl: this.$setting.fdsDomain,
      linkUrl: [],
      maessage: {},
      logoUrl1,
      logoUrl2
    };
  },
  mounted() {
    // this.getProtal();
  },

  methods: {
  },
};
</script>

<style lang="scss">
.m-new-footer {
  &.z-web {
    padding: 50px 0 22px;
    background-color: #0f0e0c;
    color: #fff;

    .u-footer-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .u-logo {
      display: inline-block;
      width: 200px;

      .u-img {
        width: 200px;
        max-width: 200px;
      }
    }

    .u-footer-list {
      display: inline-block;
      height: 250px;
      vertical-align: top;
      margin-left: 214px;

      .m-service {
        width: 172px;
        display: inline-block;
        vertical-align: top;

        .detail-tlt {
          font-size: 18px;
          margin-bottom: 16px;
        }

        .detail-list {
          margin-bottom: 24px;
          color: #f5f5f5;

          span {
            opacity: 0.5;
            line-height: 30px;
          }
        }
      }

      .u-link {
        color: #fff;
        opacity: 0.5;
        line-height: 30px;
      }
    }

    .links-code {
      text-align: center;
      float: right;

      img {
        width: 122px;
        height: 122px;
      }

      p {
        line-height: 14px;
        padding-top: 10px;
        opacity: 0.6;
      }
    }

    .u-footer-info {
      width: 100%;
      line-height: 20px;
      text-align: center;
      color: #6f6f6f;
      font-size: 0;

      .m-info-list {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        font-size: 14px;

        .link {
          color: #f5f5f5;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
