import Vue from "vue";
import Vuex from "vuex";
import { Axios } from "@/common/axios";
import cookieUtil from "./cookieUtil";

Vue.use(Vuex);

const utils = {
  getDelayTime(delay) {
    return new Date().getTime() + delay;
  },
  isExpired(time) {
    return time < new Date().getTime();
  }
};

const state = {
  loginStatus: false,
  isReady: false,
  access_token: "",
  activityCache: "",
  user_info: null,
  scrollLock: false,
  isFirstLogin: false,
  personalList: [],
  userInfo: {},
  isNewRemind: false,
  total: "",
  show: true
};

const getters = {
  isReady: state => {
    return state.isReady;
  },
  personalList: state => state.personalList,
  total: state => state.total,
  userInfo: state => state.userInfo,
  getAccessToken: state => {
    if (!state.access_token) {
      state.access_token = cookieUtil.get("ACCESS_TOKEN");
    }
    return state.access_token;
  },
  getActivityCache: state => {
    if (!state.activityCache) {
      state.activityCache = cookieUtil.get("ACTIVITY_CACHE");
    }
    return state.activityCache;
  },
  getUserInfo: state => {
    if (!state.user_info) {
      state.user_info = localStorage.getItem("USERINFO")
        ? JSON.parse(localStorage.getItem("USERINFO"))
        : null;
    }
    return state.user_info;
  },
  getIsFirstLogin: state => {
    if (!state.isFirstLogin) {
      state.isFirstLogin = localStorage.getItem("ISFIRSTLOGIN")
        ? JSON.parse(localStorage.getItem("ISFIRSTLOGIN"))
        : null;
    }
    return state.isFirstLogin;
  },
  getLoginStatus: state => {
    return state.loginStatus;
  },
  isScrollLocked: state => {
    return state.scrollLock;
  },
  isShow: state => {
    return state.show
  }
};

const mutations = {
  getList(state, payload) {
    state.personalList = payload.personalList
    state.total = payload.total
  },
  getUser(state, userInfo) {
    state.userInfo = userInfo || {}
    state.isNewRemind = userInfo.doneNewRemind
  },
  updateIsNewRemind(state, isNewRemind) {
    state.isNewRemind = isNewRemind
  },
  lockScroll(state) {
    state.scrollLock = true;
  },
  releaseScroll(state) {
    state.scrollLock = false;
  },
  pageReady(state) {
    state.isReady = true;
  },
  isShow(state) {
    state.show = false;
  },
  UPDATE_TOKEN(state, token) {
    state.access_token = token.token;
    // console.log(new Date().getTime())
    // var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000); //15min
    var expires = new Date(token.exp - 60 * 1000);
    cookieUtil.set({
      key: "ACCESS_TOKEN",
      value: token.token,
      expires: expires
    });
  },
  UPDATE_USER_INFO(state, user_info) {
    // let exp = new Date(new Date().getTime() + param.token.exp)
    state.user_info = user_info;
    localStorage.setItem("USERINFO", JSON.stringify(user_info));
    // cookieUtil.set({
    //   key: 'USER_INFO',
    //   value: user_info
    // })
  },
  UPDATE_ISFIRST_LOGIN(state, isFirstLogin) {
    state.isFirstLogin = isFirstLogin;
    localStorage.setItem("ISFIRSTLOGIN", JSON.stringify(isFirstLogin));
  },
  UPDATE_LOGIN_STATUS(state, login_status) {
    state.loginStatus = login_status;
  },
  LOGOUT(state) {
    state.access_token = "";
    state.user_info = null;
    state.activityCache = "";
    cookieUtil.remove({
      key: "ACCESS_TOKEN"
    });
    cookieUtil.remove({
      key: "ACTIVITY_CACHE"
    });
    localStorage.removeItem("USERINFO");
  }
};

const actions = {
  getUser({ commit }) {
   return new Promise((resolve, reject) => {
    Axios.get('/user/info').then(res => {
      resolve(res)
      commit('getUser', res.result)
    }).catch(err => {
      reject(err)
    })
   })
  },
  getList({ commit }, obj) {
    return new Promise((resolve, reject) => {     
      Axios.get("/course/getMyCourse", {params:{ needPage: obj.needPage, name: obj.name }}).then(res => {
        resolve(res)
        commit('getList', {personalList: res.result.records, total: res.result.total})
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateUserInfo({ commit }, userInfo) {
    commit("UPDATE_USER_INFO", userInfo);
  },
  updateIsFirstLogin({ commit }, isFirstLogin) {
    commit("UPDATE_ISFIRST_LOGIN", isFirstLogin);
  },
  login({ commit }, token) {
    commit("UPDATE_TOKEN", token);
    // commit('UPDATE_USER_INFO', data)
  },
  logout({ commit }) {
    return Axios.post("/api/web/login/logoutMooc")
      .then(json => {
        console.log(json);
        // 在iframe中logout
        if (json.data) {
          json.data.forEach(el => {
            document.append(
              `<iframe src="${el}" style="display: none"></iframe>`
            );
          });
        }
        return json;
      })
      .catch(msg => {
        console.log(msg);
        // location.href = "/login";
      })
      .finally(() => {
        commit("LOGOUT");
        setTimeout(() => {
          location.href = "/login";
        }, 3000);
      });
  },
  getActivityCache({ commit }) {
    return new Promise((resolve, reject) => {
      let cache = getters.getActivityCache(state);
      if (cache) {
        resolve(cache);
      } else {
        Axios.post("/api/web/activity/getActivityCacheId")
          .then(json => {
            cookieUtil.set({
              key: "ACTIVITY_CACHE",
              value: json.data,
              expires: new Date(new Date().getTime() + 15 * 60 * 1000) //15min
            });
            resolve(json.data);
          })
          .catch(msg => {
            console.log(msg);
            reject(msg);
          });
      }
    });
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
