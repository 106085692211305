/**
 * @author ctwang
 * @date 2019-05-16
 * @desc 不同源情况下，通过图片url下载图片
 */

export default {
    //  data: URLs方式
    downloadByData: (url, imgName) => {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
  
        // 兼容ie
        if (canvas.msToBlob) {
          ie_download(canvas.msToBlob(), url, imgName)
          return
        }
  
        let ext = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase()
        let dataURL = canvas.toDataURL('image/' + ext)
        download(dataURL, imgName)
      }
    },
    //  blob: URLs方式
    downloadByBlob: (url, imgName) => {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
  
        // 兼容ie
        if (canvas.msToBlob) {
          ie_download(canvas.msToBlob(), url, imgName)
          return
        }
  
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          download(url, imgName)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    }
  }
  
  // chrome, firefox, safari 等下载
  function download(href, name = 'defaultImgName') {
    let eleLink = document.createElement('a')
  
    // 火狐中，需要把 a 标签添加到页面中
    document.body.appendChild(eleLink);
    eleLink.style.display = 'none';
  
    eleLink.download = name
    eleLink.href = href
    eleLink.click()
    eleLink.remove()
  }
  
  // ie 下载
  function ie_download(blob, url, name = 'defaultImgName') {
    let ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase()
    window.navigator.msSaveBlob(blob, name + '.' + ext);
  }