<template>
  <div class="dialog-box1" v-show="show">
    <div class="gift-dialog">
      <div :class="`${info.name ? 'dialog-logo noheadr' : 'dialog-logo'}`">
        {{ info.name || '' }}
      </div>
      <div class="dialog-title">{{ info.title }}</div>
      <div class="dialog-btn" @click="gogo">{{ info.btnText }}</div>
      <div class="dialog-close" @click="close">
        <img :src="closeUrl" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import closeUrl from '@/assets/close.svg'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: {},
    },
  },
  watch: {
    isShow(val) {
      this.show = val
    },
  },
  data() {
    return {
      closeUrl,
      show: this.isShow,
    }
  },
  methods: {
    gogo() {
      if (this.info.btnText === '查看课程礼包') {
        location.href = '/personal/index?t=4&course=1'
        return
      }
      location.href = `${process.env.VUE_APP_COMPANY_URL}?referrer=${encodeURIComponent(location.href)}&subsystemId=1`
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss">
.dialog-box1 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  .gift-dialog {
    margin: 100px auto;
    width: 382px;
    height: 316px;
    background: #fff;
    padding-top: 50px;
    border-radius: 5px;
    position: relative;
    .dialog-logo {
      color: #fff;
      font-size: 28px;
      line-height: 66px;
      width: 66px;
      height: 66px;
      background: #3360ff;
      border-radius: 50%;
      text-align: center;
      margin: 0 auto;
      background-image: url('../assets/tx.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .dialog-title {
      font-size: 14px;
      line-height: 100px;
      text-align: center;
      color: #000000;
    }
    .dialog-btn {
      margin: 20px auto;
      background: #3360ff;
      width: 318px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
    .dialog-close {
      position: absolute;
      top: -20px;
      right: -20px;
      cursor: pointer;
    }
    .noheadr {
      background-image: none !important;
    }
  }
}
</style>
