import Utils from "@/common/utils";

// 公共配置
const common = {
  title_suffix: "易学园-网易数创产业平台",
  appAgreement: "1099787454317992321",
  AppPrivacyAgreement: "1056788993443423133"
};

// production
const production = {
  baseURL: "/api",
  fdsDomain: "/api",
  adminUrl: "https://edu.163yun.com/admin",
  indexURL: "https://edu.163yun.com",
  loginUrl: "https://console.g.163yun.com/login?referrer=https://edu.163yun.com",
  platUrl: "https://console.g.163yun.com"

};
// test
const test = {
  baseURL: "/api",
  fdsDomain: "/api",
  adminUrl: "https://edu-test.163yun.com/admin",
  indexURL: "https://edu-test.163yun.com",
  loginUrl: "https://console-test.g.163yun.com/login?referrer=https://edu-test.163yun.com",
  platUrl: "https://console-test.g.163yun.com"
};
// dev
const dev = {
  // baseURL: "http://api.net1637.com:33",
  baseURL: "/api",
  // baseURL: "http://localhost:8083",
  // baseURL: "http://192.168.1.157:8080",
  adminUrl: "http://creative-course-test.163yun.com",
  fdsDomain: "http://creative-course-test.163yun.com",
  indexURL: "https://edu-test.163yun.com",
  loginUrl: "https://console-test.g.163yun.com/login?referrer=https://edu-test.163yun.com",
  platUrl: "https://console-test.g.163yun.com"
  // fdsDomain: "http://localhost:8083/proxy"
  // appAgreement: '1099787454317992321',
};

export const setting = Utils.Utils.extend(
  true, {},
  common,
  process.env.VUE_APP_MODE === "production" ?
    production :
    process.env.VUE_APP_MODE === "test" ?
      test :
      dev
);
// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: function (Vue, Option) {
    Object.defineProperty(Vue.prototype, "$setting", {
      value: setting
    });
  },
  setting
};