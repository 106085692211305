import store from './store'
import { Axios } from './axios'
/**暂不使用 */
const isLogin = function(router) {
  return new Promise((resolve, reject) => {
    let accessToken = store.getters.getAccessToken
    // console.log(router)
    if(!accessToken) {
      reject('未登录！')
    } else {
      // console.log(Axios)
      Axios.post('/api/web/user/getUserInfo')
        .then((res) => {
          store.dispatch('updateUserInfo', res.data)
          store.commit('UPDATE_LOGIN_STATUS', true)
          resolve()
        }).catch((error) => {
          // store.commit('UPDATE_LOGIN_STATUS', !router.meta.needLogin)
          reject(error)
        })
    }
  })
}

export default isLogin
