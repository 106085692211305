<script>
import { realImageUrl } from '@/utils/utils'
export default {
  props: {
    img: {
      type: String,
      default: ''
    },
    img2x: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    className: {
      type: [String, Array],
      default: ''
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgt() {
      if (this.isSupportWebp) {
        return realImageUrl(this.img, this.isSupportWebp)
      }
      return this.img
    },
    img2xt() {
      if (this.isSupportWebp && this.img2x) {
        return realImageUrl(this.img2x, this.isSupportWebp)
      }
      return this.img2x
    }
  },
  render () {
    let ImageNode
    const directives = [
      {
        name: 'img',
        value: this.imgt,
        modifiers: {
          lazy: this.lazy
        }
      }
    ]
    ImageNode = (
      <img 
        {...{ directives }} 
        class={this.className} 
        src={this.imgt} 
        alt={this.alt} 
        srcset={this.img2xt ? `${this.imgt} 1x,${this.img2xt} 2x` : ''}
      />
    )
    return ImageNode
  }
}
</script>
