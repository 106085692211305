// import InputPrice from "@/components/input/InputPrice";

function config(vue) {
  // vue.component("input-price", InputPrice);
}

export default {
  install: function(vue) {
    config(vue);
  }
};
