import axios from "axios";
import store from "@/common/store";
import qs from "qs";
import { setting } from "@/config/setting";
import { Utils } from "@/common/utils";
import { Message } from "element-ui";

const Toast = msg => {
  return new Promise(resolve => {
    Message({
      message: msg,
      type: "error",
      duration: 2000,
      onClose: () => {
        resolve();
      }
    });
  });
};

export const Axios = axios.create({
  baseURL: setting.baseURL,
  // baseURL: 'http://api.web.haier.qingdao.cosmoplat.com',
  timeout: 20000,
  responseType: "json",
  withCredentials: true, // 是否允许带cookie这些
  headers: {
    'Content-Type': 'application/json'
    // "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" // Content-Type": "multipart/form-data
  }
});

const getAxios = (vue, option) => {
  if (option && option.router) {
    Axios.interceptors.request.use(config => {
      config.router = option.router;
      return config;
    });
  }
  return Axios;
};

Axios.getx = function (url, config) {
  return new Promise((resolve, reject) =>
    resolve(
      Axios.get(
        url,
        addHeader(config, {
          "Method-Slient": true
        })
      )
    )
  );
};

Axios.postx = function (url, data, config) {
  return new Promise((resolve, reject) =>
    resolve(
      Axios.post(
        url,
        data,
        addHeader(config, {
          "Method-Slient": true
        })
      )
    )
  );
};

Axios.getu = function (url, config) {
  return new Promise((resolve, reject) =>
    resolve(
      Axios.get(
        url,
        addHeader(config, {
          "Need-User": true
        })
      )
    )
  );
};

Axios.postu = function (url, data, config) {
  return new Promise((resolve, reject) =>
    resolve(
      Axios.post(
        url,
        data,
        addHeader(config, {
          "Need-User": true
        })
      )
    )
  );
};

function addHeader(config, ...headers) {
  let add = {};
  headers.forEach(el => {
    Utils.extend(true, add, el);
  });
  return Utils.extend(true, config || {}, {
    headers: add
  });
}

// request 拦截器
Axios.interceptors.request.use(
  config => {
    // console.log(config);
    // 序列化
    if (
      config.headers["Content-Type"] ===
      "application/x-www-form-urlencoded;charset=utf-8" &&
      config.data
    ) {
      config.data = qs.stringify(config.data) || config.data;
    }
    // let token = store.getters.getAccessToken;
    // 存在token
    // if (token) {
    //   config.headers["Authorization"] = token;
    // } else {
    //   // 清除userinfo缓存
    //   store.commit("LOGOUT");
    //   if (config.router.app.$route.meta.needLogin === true) {
    //     location.href = "/login";
    //     return;
    //   }
    // }
    return config;
  },
  err => {
    Message.error({
      showClose: false,
      message: err || "发起请求出错"
    });
    Promise.reject(err);
  }
);

// response 拦截器
Axios.interceptors.response.use(
  response => {
    let { code = 0, message, data, requestId, result } = response.data;
    // let pathName = response.config.router.history.current.path
    // console.log('code', response.data)
    if (code === 403) {
      // window.location.href = `?referrer=${encodeURIComponent(location.href)}`;
    }
    if (code !== 200) {
      if ( 
        response.config.headers["Need-User"] !== true &&
        (response.config.headers["Method-Slient"] === true ||
          response.config.router.currentRoute.meta.needLogin !== true)
      ) {
        return Promise.reject(response.data);
      }
      switch (code) {
        // todo code待更改和后端api沟通
        // 未授权
        // case 10101:
        // case 10104:
        //   Toast(message);
        //   store.dispatchstore.dispatch("logout").then(() => {
        //     // location.reload() // 为了重新实例化vue-router对象 避免bug
        //     location.href = "/login";
        //   });
        //   break;
        // // 未认证
        // case 10100:
        //   Toast("请登录后在进行操作").then(() => {
        //     location.href = "/login";
        //   });
        //   break;
        // // token过期
        // case 10102:
        //   Toast(message).then(() => {
        //     location.href = "/login";
        //   });
        //   break;
        default:
          Toast(message);
          break;
      }
      return Promise.reject(response.data);
    } else {
      return response.data;
    }
  },
  error => {
    Message({
      message: "网络异常！请重试",
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: function (Vue, Option) {
    Object.defineProperty(Vue.prototype, "$http", {
      value: getAxios(Vue, Option)
    });
  },
  Axios
};
