export function isMobileF() {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  return flag
}

// 是否支持webp格式图片
export const isSupportWebp = () => {
  try {
    return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
  } catch (err) {
    return false
  }
}

// 替换jpg,png图片后缀为webp
export const dealImageSuffix = (url) => {
  let returnUrl = url
  if (url.indexOf('nos-jd') !== -1) {
    returnUrl = `${returnUrl}&imageView&type=webp&quality=100`
    return returnUrl
  }
  if (url.indexOf('nosdn') !== -1) {
    if (returnUrl.indexOf('https') !== -1) {
      returnUrl = `${returnUrl}&type=webp`
      return returnUrl
    } else if (returnUrl.indexOf('http') !== -1) {
      returnUrl = `${returnUrl.replace('http', 'https')}&type=webp`
      return returnUrl
    } else {
      return returnUrl
    }
  }
  if (url.indexOf('nos.netease.com') !== -1) {
    if (returnUrl.indexOf('https') !== -1) {
      returnUrl = `${returnUrl}?imageView&type=webp&quality=100`
      return returnUrl
    } else if (returnUrl.indexOf('http') !== -1) {
      returnUrl = `${returnUrl.replace('http', 'https')}?imageView&type=webp&quality=100`
      return returnUrl
    } else {
      return returnUrl
    }
  }
  return returnUrl
}

// 是否是链接
export const isUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regexp = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
  return regexp.test(url)
}

// 是否是SVG
export const isNotSvg = (url) => {
  return url.indexOf('.svg') === -1
}

export const realImageUrl = (imgUrl, isSupportWebp) => {
  // 本地图片且非svg 将路径转换为nos图片地址
  if (imgUrl && !isUrl(imgUrl) && isNotSvg(imgUrl)) {
    return imgUrl
  }
  if (isUrl(imgUrl) && isSupportWebp) {
    return dealImageSuffix(imgUrl)
  }
  return imgUrl
}

export function dealLocationSearch() {
  const urlSearch = location.search;
  const substrUrlSearch = urlSearch.substr(1);  //从起始索引号提取字符串中指定数目的字符
  let arr = substrUrlSearch.split("&"), obj = {}, newarr = [];
  arr.forEach((value) => {
      newarr = value.split("=");
      if(typeof obj[newarr[0]] === "undefined"){  
      obj[newarr[0]] = newarr[1];  
      }        	
  });
  return obj;
}