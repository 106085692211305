<template>
  <div :class="top === 0 && routeName === 'index' ? 'g-hd' : 'g-hd'">
    <div class="flex-header">
      <div class="nav-logo">
        <a :href="indexURL">
          <img class="logoicon" aria-hidden="true" style="font-size: 35px" :src="yixueyuan" alt="" />
        </a>
      </div>
      <div class="nav-menu">
        <div class="nav-menu-list">
          <div
            v-for="nav in navMenuList"
            :key="nav.path"
            :class="['nav-menu-item', activePath === nav.path ? 'is-active' : '']"
            @click="goPage(nav)"
          >{{ nav.name }}</div>
        </div>
      </div>
      <div class="nav-search">
        <el-input placeholder="请输入课程名称" class="searchBar-input" size="medium" v-model.trim="courseKey" @input="keyChange()" @keyup.native.enter="getCourse(`${courseKey || ''}`)">
          <i @click="getCourse(`${courseKey || ''}`)" slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div class="m-user-msg" v-if="userMsg && userMsg.id">
        <div :style="top === 0 && routeName === 'index' ? 'font-size:16px;font-weight:600;margin-right:70px' : 'color:rgba(0,0,0,.65);font-size:16px;font-weight:600'" @click="gomycourse" class="my-course" v-if="!ischangsha &&(userMsg.enableIndenpentPortal === true)"><i class="el-icon-notebook-2"></i><span style="font-size:14px; font-weight:600"> 我的课程</span></div>
        <el-dropdown :hide-timeout="200" trigger="hover">
          <a href="javascript:void(0);" class="m-menu-box el-dropdown-link">
            <el-badge :value="msgNum > 99 ? '99+' : msgNum" class="message-num" :hidden="msgNum > 0 ? false : true">
              <img :src="adminurl" alt class="m-user-head" />
            </el-badge>
            <span class="m-menu-btn m-header-btn">
              <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
            </span>
          </a>
          <el-dropdown-menu slot="dropdown" class="app-logout-dropdown">
            <div class="head">
              <img
                class="head-img"
                :src="adminurl"
              >
              <div class="name">
                <p class="personal-name c-ellipsis">
                  {{ userMsg ? userMsg.name : '' }}
                </p>
                <p class="company-name c-ellipsis">
                  {{ userMsg.enterpriseName || userMsg.parkName || '网易数创产业平台' }}
                </p>
              </div>
              <p class="inline" />
            </div>
            <el-dropdown-item
              v-if="userMsg.role == 'guest' || userMsg.role == 'admin' || userMsg.role == 'user'"
              @click.native="openCourse()"
            >
              我的学习
            </el-dropdown-item>
            <el-dropdown-item
              v-if="userMsg && userMsg.role == 'admin'"
              @click.native="toAdmin()"
            >
              后台管理
            </el-dropdown-item>
            <el-dropdown-item
              class="message-center clearfix"
              @click.native="goMsg()"
            >
              消息中心
              <el-badge
                class="mark"
                :value="msgNum"
                :hidden="msgNum > 0 ? false : true"
                :max="99"
              />
            </el-dropdown-item>
            <el-dropdown-item @click.native="loginOut()">
              退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else>
        <el-button type="text" @click="toLogin()" size="medium" :class="top === 0 && routeName === 'index' ? 'login-btn' : 'login-btn'"> 登录/注册 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge'
import adminurl from '@/assets/tx.svg'
import yixueyuan1 from '@/assets/yixueyuan1.svg'
import yixueyuan from '@/assets/yixueyuan.svg'
import { mapGetters } from 'vuex'

export default {
  props: ['routeName', 'userMsg', 'msgNum'],
  name: 'Header',
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    $route(to) {
      this.courseKey = to.query.key || ''
    }
  },
  data() {
    return {
      ischangsha: undefined,
      courseKey: '',
      maessage: {},
      indexURL: this.$setting.indexURL,
      top: 0,
      adminurl,
      yixueyuan,
      yixueyuan1,
      info: JSON.parse(localStorage.getItem('userInfo')) || {},
      activePath: '/index',
      navMenuList: [
        {
          name: '首页',
          path: '/index'
        },
        {
          name: '课程列表',
          path: '/course'
        },
      ]
    }
  },
  mounted() {
    this.activePath = location.pathname === '/' ? '/index' : location.pathname
    this.ischangsha = location.host === process.env.VUE_APP_CSZYHOST
    window.addEventListener('scroll', () => {
      this.top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    })
  },
  methods: {
    gomycourse() {
      location.href = this.userInfo.exclusiveUrl || '/personal/index?t=4&course=1'
    },
    // 跳转站内信地址
    goMsg() {
      window.open(this.$setting.platUrl + '/stationLetter')
    },
    handleSelect(index) {
      location.href = index
    },
    toAdmin() {
      location.href = process.env.VUE_APP_ADMIN_URL
    },
    toLogin() {
      const locationSearch = location.search
      let params = ''
      if (locationSearch.length > 1) {
        params = encodeURIComponent(locationSearch.substring(1))
      }
      let referrerUrl = `${location.origin}${location.pathname}${params ? '?' + params : ''}`
      location.href = `${process.env.VUE_APP_LOGIN_URL}referrer=${referrerUrl}`
    },
    keyChange() {
      this.$router.push({
        query: merge(this.$route.query, { key: this.courseKey }),
      })
    },
    getCourse(val) {
      // if (val === '') {
      //   return
      // } 
      if (location.host === process.env.VUE_APP_CSZYHOST) {
        location.href = `/?key=${val}`
         //     this.$nextTick( () => {
        //       document.documentElement.scrollTop = 700
        //     })
        // this.$store.dispatch('getList', this.courseKey).then( res =>{
        //   if (res.code === 200) {
        //   }
        // })
        // this.$store.commit('isShow')
        return
      } else {
        location.href = `/course?type=course&pathFalg=default&categoryId=&isOnline=&key=${val}`
      }
    },
    // 退出登录
    loginOut() {
      this.$message.success('正在退出...')
      this.$http
        .get('/login/out')
        .then(() => {
          this.$cookies.remove('urs_u')
          this.$cookies.remove('urs_t')
          localStorage.removeItem('userInfo')
          sessionStorage.removeItem('setLogin')
          location.reload()
          // location.href = `${process.env.VUE_APP_LOGIN_URL}referrer=${location.href}`
        })
        .catch(() => {
          localStorage.removeItem('userInfo')
          sessionStorage.removeItem('setLogin')
          // location.href = `${process.env.VUE_APP_LOGIN_URL}referrer=${location.href}`
          location.reload()
        })
    },
    //进入个人中心个人信息
    openUser() {
      location.href = '/personal/index'
    },
    openCourse() {
      location.href = '/personal/index?t=4'
    },
    //打开证书修改
    openCertificate() {
      location.href = '/personal/index?t=2'
    },
    //打开证书修改
    openPass() {
      location.href = '/personal/index?t=3'
    },
    // 获取配置信息并缓存
    getMessage() {
      let protalMsg = localStorage.getItem('maessage') ? JSON.parse(localStorage.getItem('maessage')) : []
      if (protalMsg.length < 1 || !protalMsg.time || protalMsg.time < new Date().getTime()) {
        this.$http
          .post('/api/web/config/getConfig')
          .then((res) => {
            this.maessage = res.data
            localStorage.setItem(
              'maessage',
              JSON.stringify({
                time: new Date().getTime() + 1000 * 60 * 2,
                content: res.data,
              })
            )
            //通过缓存获取网站基本配置信息
            let protalContent = JSON.parse(localStorage.getItem('maessage'))
            if (protalContent != null) {
              this.$setting.title_suffix = protalContent.content.title
            }
          })
          .catch(() => {})
      } else {
        this.maessage = protalMsg.content
        //通过缓存获取网站基本配置信息
        let protalContent = JSON.parse(localStorage.getItem('maessage'))
        if (protalContent != null) {
          this.$setting.title_suffix = protalContent.content.title
        }
      }
    },
    // 获取友情链接并缓存
    getLinks() {
      let protalMsg = localStorage.getItem('msgLinks') ? JSON.parse(localStorage.getItem('msgLinks')) : []
      if (protalMsg.length < 1 || !protalMsg.time || protalMsg.time < new Date().getTime()) {
        this.$http
          .post('/api/web/config/getLinks')
          .then((res) => {
            this.links = res.data
            localStorage.setItem(
              'msgLinks',
              JSON.stringify({
                time: new Date().getTime() + 1000 * 60 * 2,
                links: res.data,
              })
            )
          })
          .catch(() => {})
      }
    },
    // 页面跳转
    goPage(navInfo) {
      if (navInfo.path === '/course') {
        const val = ''
        if (location.host === process.env.VUE_APP_CSZYHOST) {
          location.href = `/?key=${val}`
          return
        } else {
          location.href = `/course?type=course&pathFalg=default&categoryId=&isOnline=&key=${val}`
        }
      } else {
        location.href = navInfo.path
      }
    }
  },
}
</script>

<style lang="scss">
.g-hd {
  background-color: #fff;
  transition: all 0.2s;

  &.f-top {
    // background-color: #091b3b;
    background-color: #fff;
    border: none;

  }

  .flex-header {
    background-color: transparent;

    .m-menu-btn .el-icon-arrow-down {
      color: #707070;
    }
  }
}
.show {
  color: #2f3440 !important;
  border-bottom: 3px solid #1e74f4 !important;
}
.nav-logo {
  display: inline-block;
  max-width: 200px;
  height: 35px;
  margin-right: 30px;
  vertical-align: middle;
  // margin-left: 20px;
  img {
    width: auto;
    max-width: 200px;
    height: 100%;
  }
}
.m-header {
  .el-menu--horizontal > .el-menu-item {
    height: 80px;
    line-height: 80px;
  }
}
.m-header .nav-menu {
  display: inline-block;
  vertical-align: middle;
  .el-menu-item {
    border-bottom: none;
  }
}
.m-user-msg {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  .el-dropdown {
    height: 90%;
    display: flex;
    align-items: center;
  }
  .message-num {
    margin-right: 5px;

    .el-icon-bell {
      font-size: 16px;
    }

    .el-badge__content {
      top: -2px;
      background: #f5222d;
      line-height: 16px;
      border: 1px solid #f5222d;
    }
  }
  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.nav-right {
  float: right;
  margin-top: 22px;
  div {
    float: left;
    line-height: 34px;
    a {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  height: 60px;
  .nav-search {
    flex: 1;
    margin-right: 20px;
    max-width: 420px;
    font-size: 0;

    .el-input {
      display: inline-block;
      width: 340px;
      height: 34px;
      line-height: 34px;
      vertical-align: middle;
    }
    .el-button--primary {
      display: inline-block;
      cursor: pointer;
      width: 110px;
      height: 37px;
      line-height: 37px;
      border-radius: 0;
      vertical-align: middle;
      padding: 0;
      overflow: hidden;
      background-color: #1e74f4;
      border: 1px solid #1e74f4;
    }
    .search-btn {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    .el-input__suffix {
      right: 0;
    }
    // .el-icon-search {
    //   cursor: pointer;
    //   font-size: 18px;
    //   padding: 7px 8px 10px;
    //   vertical-align: middle;
    // }
    // .el-input__inner {
    //   height: 37px;
    //   border: none;
    //   border: solid 1px #e2e6eb;
    //   border-radius: 0;
    //   border-top-left-radius: 3px;
    //   border-bottom-left-radius: 3px;
    //   line-height: 34px;
    //   height: 34px;
    // }
    .el-input__inner:focus {
      border-color: #1e74f4;
    }

    .el-input__inner::placeholder {
      color: #999 !important;
    }

    .searchBar-input .el-input__inner {
      border-color: transparent;
    }
  }
  // .login-btn {
  //   color: #fff !important;
  // }
}
.login-btn {
  color: #3360FF!important;
}
.my-course {
  position: absolute;
  color: #fff;
  left: -110px;
  top: 10px;
  cursor: pointer;
}

.searchBar-input.el-input--medium {
  border-radius: 34px;
  line-height: 34px;
  .el-input__inner {
    border: solid 1px #e2e6eb;
    border-radius: 37px;
    line-height: 32px;
    height: 34px;
    background: #F1F1F3;
    transition: all 0.25s ease-in;
    padding-left: 18px;
    &:focus {
      background: #fff;
      border-color: #8590a6;
    }
  }
  .el-input__suffix {
    padding-right: 15px;
    cursor: pointer;
  }
  .el-icon-search {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
}
</style>

<style lang="scss">
.app-logout-dropdown /deep/ .el-badge__content {
  background: #ee4c4b;
  margin-left: 5px;
  position: absolute;
  top: -12px;
}
.el-popper /deep/ .popper__arrow {
  display: none;
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
  line-height: 36px;
  padding: 0 24px;
  color: #666;
  transition: all 0.25s ease-in;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f7f7f9;
  font-size: 14px;
  color: #000;
  line-height: 36px;
  // font-weight: 600;

  .el-badge {
    font-weight: normal;
  }
}

.app-logout-dropdown {
  width: 292px;
  box-sizing: border-box;
  padding: 12px 0 16px;
  box-shadow: 0 10px 80px 10px rgba(1, 3, 10, 0.1);
  border-radius: 10px;
  margin-top: 2px !important;
  border: none;
}

.head {
  padding: 0 24px;
  position: relative;
  margin-bottom: 10px;

  .head-img {
    width: 50px;
    height: 50px;
    vertical-align: sub;
    margin-right: 16px;
    margin-bottom: 10px;
    margin-top: 12px;
    border-radius: 50%;
  }

  .name {
    display: inline-block;
    position: absolute;
    top: 14px;
    left: 90px;
    right: 24px;
    bottom: 12px;
    font-size: 0;

    .personal-name {
      height: 25px;
      font-size: 18px;
      line-height: 25px;
      color: #000;
      margin-bottom: 0;
      font-weight: 600;
    }

    .company-name {
      margin-top: 0;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
  }

  .inline {
    width: 100%;
    height: 1px;
    background: #e7e7e9;
  }
}
.el-popper[x-placement] .popper__arrow {
  display: none;
}
.c-ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.app-logout-dropdown {
  &.el-popper {
    border-radius: 6px;
  }
  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #F7F7F9;
    color: #000;
  }
}
</style>

<style lang="scss">
.nav-menu {
  &-list {

  }
  &-item {
    float: left;
    margin-right: 40px;
    font-size: 15px;
    cursor: pointer;
    transition: color .2s ease-in;
    &.is-active {
      color: #3360FF;
      font-weight: 400;
    }
    &:hover {
      color: #3360FF;
      font-weight: 400;
    }
  }
}
</style>